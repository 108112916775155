import { post, postCache } from '@/core/ajax'
import { singleRequest } from '@/core/util'

/**
 * 获取商家基本信息
 */
export const getUserInfo = () => {
  const LOGININFOURL = '/business/brandHub/getSupplierInfo'
  return singleRequest(LOGININFOURL, () => postCache(LOGININFOURL))
}

/**
 * 获取登录信息
 */
export const get = () => {
  const LOGININFOURL = '/api/user/get.htm'
  return singleRequest(LOGININFOURL, () => post(LOGININFOURL))
}
