export const TITLE = '供应商后台' // 站点名称
export const LOGO = 'https://img01sit.meituncdn.com/group1/M00/34/F2/a0f2b6fa1fd3469ab9b2966f8e034344.png' // 站点logo
export const QRLOGO = 'https://pic03.babytreeimg.com/img/icons/pregnancy/114.png?v=20110707' // 站点logo
export const LOGINURL = 'http://business.meitun.com/business/tologout.htm' //登录登出地址
export const NOLOGIN = '000002' // 商家未入驻
export const PARAMERROR = '000003' // 系统异常
export const NOUSERINFO = '000004' // 用户信息不存在
export const NOREGISTBRAND = '000001' // 用户信息未注册品牌号
export const OUTTIME = 100000  // 接口超时时间
export const TOASTDURATION = 2 // toast 存在时间
export const TOASTCOUNT = 3 // 同时存在的toast个数
export const ORDERSTATUSMAP = { // 订单状态枚举
  '2': '待支付',
  '4': '待服务',
  '6': '服务完成',
  '0': '已取消'
}
export const BANKSTATUSMAP = { // 财务-资金管理-银行账户状态
  '10': '新建',
  '20': '银行认证成功',
  '30': '银行认证失败',
  '40': '银行认证中',
  '50': '待业务审核',
  '60': '业务审核成功',
  '70': '业务审核拒绝',
  '80': '已删除'
}
export const fundMap = {
  '100': '收入',
  '200': '提现'
}

export const SIGN_MENU = '/workorder/list' // 需要获取右上角标签的菜单
