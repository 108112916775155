import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import './index.less'

const LoginBlcok = (props) => {
  const { loginOpt } = props
  return (
    <div className='login-block flex-center'>
      <div className='logo'></div>
      <Button className='btn' type='primary' shape='round' size='large' onClick={loginOpt}>登录</Button>
      <Button className='btn' ghost shape='round' size='large'>注册</Button>
    </div>
  )
}
LoginBlcok.propTypes = {
  loginOpt: PropTypes.func
}

export default LoginBlcok
