import React from 'react'
import PropTypes from 'prop-types'
// import { Button } from 'antd'
import './index.less'

const LoginInfo = (props) => {
  const { info = '' } = props
  return (
    <div className='error-wrap flex-center'>
      <div className='logo'></div>
      <p className='name'></p>
      <p className='error'>{info}</p>
      {/* <Button className='btn' ghost shape='round' size='large'>退出登录</Button> */}
    </div>
  )
}

LoginInfo.propTypes = {
  info: PropTypes.string
}

export default LoginInfo
