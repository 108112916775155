import {post} from '@/core/ajax'
import {get} from '@/core/ajax'

// 工单列表查询条件枚举数据
export const getEnumInfo = () =>
{
  return get('/api/workOrderEnter/enumInfo.htm').then((res = {}) => res.enumMap || {}
)
}

// 工单列表查询
export const getWorkOrderList = (params) =>
{
  return get('/api/workOrderEnter/list.htm', params).then((data) => data.page || {}
)
}

// 查询工单详情
export const getWorkOrderDetail = (params) =>
{
  return get('/api/workOrderEnter/detail.htm', params).then((data) => data || {}
)
}

// 处理工单回复
export const doReply = (params) =>
{
  return post('/api/workOrderEnter/reply.htm', params).then((res) => res || {}
)
}

// 查询未处理工单数量
export const pendingCount = () =>
{
  return get('/api/workOrderEnter/pendingCount.htm').then((data) => data || {}
)
}

