import { post } from '@/core/ajax'
import hostCfg from '@/config/host.json'
const preUrl = hostCfg['sso'] || ''
const getGeeURL = '/sso/gweetInit.htm'
// const loginInURL = '/sso/loginSubmit.htm'
// const doScanLoginURL = '/sso/doScanLogin.htm'
// const getQRCodeURL = '/sso/genQRCode.htm'
// const checkQRCodeURL = '/sso/checkQRCode.htm'

const loginInURL = '/oauth/loginSubmit.htm'
const getQRCodeURL = '/oauth/genQRCode.htm'
const checkQRCodeURL = '/oauth/checkQRCode.htm'
const doScanLoginURL = '/oauth/doScanLogin.htm'

const sendSmsCodeURL = '/sso/sendSmsCode.htm'
const smsSwitchURL = '/sso/getSmsSwitch.htm'
const LoginOutURL = '/sso/tologout.htm'

/**
 * @Description: 登录
 * @Author: jialong
 * @param {type} Object
 * @return: promise
 */
export const loginIn = (param) => {
  param.smsCode = param.smsCode || ''  // 如果不存在短信验证码，加入字段
  return post(preUrl + loginInURL, param)
}

/**
 * @Description: 获取极验
 * @Author: machao
 * @param {type} Object
 * @return: promise
 */
export const getGee = () => {
  return post(preUrl + getGeeURL)
}

/**
 * @Description: 获取二维码
 * @Author: machao
 * @param {type} Object
 * @return: promise
 */
export const getQRCode = () => {
  return post(preUrl + getQRCodeURL)
}

/**
 * @Description: 检查二维码
 * @Author: machao
 * @param {type} Object
 * @return: promise
 */
export const checkQRCode = () => {
  return post(preUrl + checkQRCodeURL, {}, {autoError: false})
}


/**
 * @Description: 获取token
 * @Author: machao
 * @param {type} Object
 * @return: promise
 */
export const doScanLogin = (params) => {
  return post(preUrl + doScanLoginURL, params, {autoError: false})
}

/**
 * @Description: 发送短信
 * @Author: machao
 * @param {type} Object
 * @return: promise
 */
export const sendSmsCode = (param) => {
  return post(preUrl + sendSmsCodeURL, param)
}

/**
 * @Description: 发送短信
 * @Author: machao
 * @param {type} Object
 * @return: promise
 */
export const smsSwitch = (param) => {
  return post(preUrl + smsSwitchURL, param)
}

/**
 * @Description: 登出
 * @Author: jialong
 * @param {type}
 * @return: promise
 */
export const loginOut = (param) => {
  return post(preUrl + LoginOutURL, param)
}


