import React from 'react'
import { Redirect } from 'react-router-dom'
import Exception from '@/views/pages/exception/index'
import Login from '@/views/pages/login'

const Exception403 = () => <Exception type='403' />
const Exception404 = () => <Exception type='404' />
const Exception500 = () => <Exception type='500' />
const NoMatch404 = () => <Redirect to='/exception/404' />

export default {
  routes: [
    {
      path: '/fe/login',
      exact: true,
      component: Login,
      hideLayout: true,
      meta: {
        subTitle: '登录',
        title: '登录'
      }
    },
    {
      path: '/fe/exception/403',
      exact: true,
      hideLayout: true,
      component: Exception403
    },
    {
      path: '/fe/exception/404',
      exact: true,
      hideLayout: true,
      component: Exception404
    },
    {
      path: '/exception/404',
      exact: true,
      hideLayout: true,
      component: Exception404
    },
    {
      path: '/fe/exception/500',
      exact: true,
      hideLayout: true,
      component: Exception500
    },
    {
      path: '/*',
      hideLayout: true,
      render: NoMatch404
    }
  ]
}
