import React, { useState } from 'react'
import LoginBlock from './loginBlock'
import LoginFrom from './loginForm'
import LoginError from './loginError'
import './index.less'

const Login = () => {
  const [loginBlockShow, setBlockShow] = useState(false) // 控制登录注册块的显示
  const [loginShow, setLoginShow] = useState(true) // 控制登录表单的显示
  const [info, setInfo] = useState('')   // 控制提示信息框的显示
  const loginOpt = () => {
    setBlockShow(false)
    setLoginShow(true)
  }
  const showInfo = (info = '') => {
    setInfo(info)
  }
  return (
    <div className='login-wrap'>
      <header>
          <span className='logo'></span>
          <span className='title'>美囤商家统一登录后台</span>
      </header>
      <div className='container'>
        <div className='banner'></div>
        {/* 登录注册展示组件，点击弹出登录表单 */}
        {loginBlockShow && <LoginBlock loginOpt = {loginOpt}/>}
        {loginShow && <LoginFrom showInfo={showInfo}/>}
        {/* 登录信息提示组件 */}
        {info && <LoginError info={info} />}
      </div>
    </div>
  )
}

export default Login
