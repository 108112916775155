/**
 * 支持无限级菜单
 * 建议控制在三级以内
 * Author: yangxi
 */

import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Menu } from 'antd'
import menuCtrl from '@/ctrl/menu'
import './index.less'
import {pendingCount} from '@/service/workorder'
import {SIGN_MENU} from '@/constant/enum'

const filterIcon = (url) => {
  return (url.includes('http')) ? url : `http://img01sit.meituncdn.com${url}`
}

// 子菜单
const subMenus = (menus, count) => {
  return (
    <For each='menu' of={menus}>
      <If condition={menu.children && menu.children.length > 0}>
        <Menu.SubMenu
          key={menu.key}
          title={
            <span>
                <If condition={menu.icon}>
                  <img className='anticon menu-item-icon' src={filterIcon(menu.icon)} alt='' />
                </If>
                <span>{menu.name}</span>
                <If condition={count>0 && menu.name=='售后管理'}>
                    <span className='menu-tip-icon'>{count}</span>
                </If>
              </span>
          }>
          {subMenus(menu.children, count)}
        </Menu.SubMenu>
      </If>
      <If condition={!menu.children || menu.children.length === 0}>
        <Menu.Item key={menu.key}>
          <Link to={menu.url}>
            <If condition={menu.icon}>
              <img className='anticon menu-item-icon' src={filterIcon(menu.icon)} alt='' />
            </If>
            <span>{menu.name}</span>
            <If condition={count>0 && menu.url==SIGN_MENU}>
              <span className='menu-tip-icon'>{count}</span>
            </If>
          </Link>
        </Menu.Item>
      </If>
    </For>
  )
}

const Menus = (props) => {
  const { location = {} } = props
  const [menus, setMenus] = useState([])
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
  const [defaultOpenKeys, setDefaultOpenKeys] = useState([])
  const [inited, setInited] = useState(false)
  const [count, setCount] = useState(0)

  /* eslint-disable react-hooks/exhaustive-deps */
  // 因为 location 属性是 react-router 默认注入的，会随着路由改变而改变，不能设置为 hook 的依赖项
  useEffect(() => {
    menuCtrl.getMenus().then((menus) => {
      setMenus(menus)
      const currentMenuInfo = menuCtrl.getCurrentMenuInfo(location)
      const { selectedKeys = [], openKeys = [] } = currentMenuInfo || {}
      setDefaultSelectedKeys(selectedKeys)
      setDefaultOpenKeys(openKeys)
      setInited(true)

      let queryWorkOderCount = false
      if (menus.length > 0) {
        menus.forEach((element) => {
          if (element.url == SIGN_MENU) {
            queryWorkOderCount = true
          } else {
            const child = element.children
            if (child && child.length > 0) {
              child.forEach((childElement) => {
                if (childElement.url == SIGN_MENU) {
                  queryWorkOderCount = true
                  return
                }
              })
            }
          }
        })
      }
      if (queryWorkOderCount) {
        pendingCount({}).then((count) => {
          setCount(count.count)
        })
      }
    })
  }, [])
  /* eslint-disable react-hooks/exhaustive-deps */

  return (
    <div className='page-menus w-full pos-r'>
      <If condition={inited}>
        <Menu
          className='menu-group'
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          mode='inline'
          theme='dark'
        >
          {subMenus(menus, count)}
        </Menu>
      </If>
    </div>
  )
}

Menus.propTypes = {
  location: PropTypes.object
}

export default withRouter(memo(Menus))
