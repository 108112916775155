import React, { Component } from 'react'
import { QRCode } from 'react-qrcode-logo'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Form, Icon, Input, Button, Col, Modal } from 'antd'
import { QRLOGO } from '@/constant/enum'
import { getGee, loginIn, getQRCode, checkQRCode, doScanLogin, sendSmsCode, smsSwitch } from '../../../../service/login'
import Geetest from 'react-geetest'
import { toast } from '@/core/util'
import qs from 'query-string'
import './index.less'

class LoginForm extends Component {

  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object,
    showInfo: PropTypes.func
  }

  checkTimes = 0

  state = {
    gee: null,
    geeReturn: null,
    qr: null,
    refreshTime: 10,
    sms: {
      disabled: false,
      txt: '获取验证码',
      time: 60
    },
    showSms: true,
    client_id: '',
    redirect_uri: ''
  }

  componentWillMount() {
    // if(localStorage.getItem('token')) {
    //   this.props.history.push('/')
    // }
    
    const query = qs.parse(window.location.search)
    const { client_id = '0'} = query
    const { redirect_uri = process.env.NODE_ENV == 'sit' ? '//supplier.meitun-test.com/choosePlatform' : '//supplier.meitun.com/choosePlatform'} = query

    this.setState({
      client_id, redirect_uri
    })
  }

  componentDidMount() {
    getGee().then((data) => {
      const gweet = JSON.parse(data.gweetStr)
      if(gweet.success === 1) {
        this.setState({
          gee: {
            gt: gweet.gt,
            challenge: gweet.challenge
          }
        })
      }
    })
    smsSwitch().then((res) => {
      if (res.smsSwitch) {  // true关闭  ，false 开启
        console.log('关闭短信')
        this.setState({
          showSms: false
        })
      }
    })
    // this.doVisibilityChange() // 如果页面隐藏不请求接口 setInterval
  }

  componentWillUnmount() {
    if(this.qrCodeInterval) {
      clearInterval(this.qrCodeInterval)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { geeReturn } = this.state
    if(!geeReturn) {
      Modal.error({
        title: '出错啦',
        content: '请先通过极验验证码'
      })
      return
    }
    this.props.form.validateFields((err, values) => {
      if(!err) {
        values.accountType = 3
        Object.assign(values, geeReturn)
        const { client_id, redirect_uri } = this.state
        loginIn(values).then((res) => {
          if(res.closeScanCode){
            // 关闭扫码登录.
            doScanLogin({client_id, redirect_uri }).then((data) => {
              if(data.url){
                location.href = data.url
              }else{
                toast.error('登录没有返回url')
              }
            }).catch(() => {
              // this.props.history.push('/')
            })  
          }else if(res.hasBindMac === 1) {
            this.getQRCodeFuc()
          }
        }).catch((res) => {
          const { data } = res
          const { errorMessage } = data
          Modal.error({
            title: '出错啦',
            content: errorMessage || '登录失败'
          })
        })
      }
    })
  }
  onGeeSuccess = (res) => {
    const { showSms } = this.state
    this.setState({
      geeReturn: res
    }, () => {
      // 如果通过极验，直接调用发送短信验证码功能
      const userName = this.props.form.getFieldValue('userName')
      if(/^1(3|4|5|6|7|8|9)\d{9}$/.test(userName) && showSms) {
        this.sendSms()
      }
    })
  }

  // 发送短信验证码
  sendSms = (type) => {
    const { sms, geeReturn } = this.state
    if(type === 'dashed') {
      return
    }
    if(!geeReturn) {
      Modal.error({
        title: '出错啦',
        content: '请先通过极验验证码'
      })
      return
    }
    if(!sms.disabled) {
      const lastSms = parseInt(localStorage.getItem('lastSms')) || 0
      if(lastSms && Date.parse(new Date()) - lastSms < 60000) {
        toast.error('短期内无法多次获取验证码')
        return
      }
      const userName = this.props.form.getFieldValue('userName')
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(userName))) {
        toast.error('手机号码有误')
        return
      }
      sendSmsCode(Object.assign({ userName }, geeReturn)).then(() => {
        localStorage.setItem('lastSms', Date.parse(new Date()))
        this.smsTimer = setInterval(() => {
          if(sms.time === 1) {
            clearInterval(this.smsTimer)
            sms.disabled = false
            sms.time = 60
            sms.txt = '获取验证码'
            this.setState({
              sms
            })
            return
          }
          sms.disabled = true
          sms.time -= 1
          sms.txt = `${sms.time}s`
          this.setState({
            sms
          })
        }, 1000)
      })
    }
  }

  getQRCodeFuc = () => {
    getQRCode().then((rr) => {
      if(rr.url) {
        this.setState({
          qr: rr.url,
          refreshTime: rr.refreshTime || 10
        }, () => {
          this.checkTimes = 0
          this.checkQRCodeFuc()
        })
      }
    }).catch(() => {
    })
  }

  // 循环检查是否通过二维码验证
  checkQRCodeFuc = () => {
    const { qr, refreshTime, client_id, redirect_uri } = this.state
    if(!qr) {
      return
    }
    if(this.qrCodeInterval) {
      clearInterval(this.qrCodeInterval)
    }
    this.qrCodeInterval = setInterval(() => {
      this.checkTimes += 1
      if(this.checkTimes === refreshTime) {
        // 10秒后重新刷新二维码
        clearInterval(this.qrCodeInterval)
        this.getQRCodeFuc()
        return
      }
      checkQRCode().then(() => {
        clearInterval(this.qrCodeInterval)
        doScanLogin({client_id, redirect_uri }).then((data) => {
          // if(data.token) {
          //   localStorage.setItem('token', data.token)
          // }
          // this.props.history.push('/')
          // location.href = '//business.meitun.com/business/biz/channel'
          
          if(data.url){
            location.href = data.url
          }else{
            toast.error('登录没有返回url')
          }
        }).catch(() => {
          // this.props.history.push('/')
        })
      }).catch(() => {
      })
    }, 1000)
  }

  jump = (type) => {
    if(type === 'forget') {
      //window.open('http://www.babytree.com/reg/forgotpwd.php?rand=1')
      Modal.info({
        title: '忘记密码？',
        content: '请在孕育APP/个人信息/修改密码'
      })
    } else if(type === 'regist') {
      Modal.info({
        title: '注册账号？',
        content: '请下载孕育APP并用手机号登录，然后在孕育APP/个人信息/修改密码根据功能引导获得登录密码'
      })
      //window.open(`http://www.babytree.com/reg/register.php?url=${encodeURIComponent(location.href)}&type=top`)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { gee, qr, sms, showSms } = this.state
    console.log('二维码数据')
    console.log(this.state)
    return (
      <Form onSubmit={this.handleSubmit} className='login-form'>
        <div className='login-form-box'>
          <div className='logo'></div>
          <p className='titleTxt'>美囤商家统一登录后台</p>
          {!qr ? (
              <div className='login-form-input-box'>
                <Form.Item wrapperCol={{ span: 20, offset: 2 }}>
                  {getFieldDecorator('userName', {
                    rules: [{ required: true, message: '请输入手机号' }]
                  })(
                    <Input
                      prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }}/>}
                      placeholder='手机号'
                      className='input'
                      ref={(grid) => {
                        this.listPanGrid = grid
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 20, offset: 2 }}>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: '请输入密码' }]
                  })(
                    <Input
                      prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }}/>}
                      type='password'
                      placeholder='密码'
                      allowClear={true}
                      className='input'
                    />
                  )}
                </Form.Item>
                {gee &&
                <Form.Item wrapperCol={{ span: 20, offset: 2 }}>
                  <Geetest
                    gt={gee.gt}
                    width='285'
                    className='gee-test'
                    onSuccess={this.onGeeSuccess}
                    challenge={gee.challenge}
                  />
                </Form.Item>}
                <If condition={showSms}>
                  <Form.Item wrapperCol={{ span: 20, offset: 2 }}>
                    {getFieldDecorator('smsCode', {
                      rules: [{ required: true, message: '请输入短信验证码' }]
                    })(
                      <Input
                        type='number'
                        allowClear={true}
                        prefix={<Icon type='message' style={{ color: 'rgba(0,0,0,.25)' }}/>}
                        className='numberinput' placeholder='短信验证码'/>
                    )}
                    <Button disabled={sms.disabled}
                            className='sms-form-button' onClick={() => this.sendSms()}>
                      {sms.txt}
                    </Button>
                  </Form.Item>
                </If>
                <Form.Item wrapperCol={{ span: 20, offset: 2 }}>
                  {/* {getFieldDecorator('remember', {
        valuePropName: 'checked',
        initialValue: true
      })(<Checkbox>记住密码</Checkbox>)} */}
                  <Button type='primary' htmlType='submit' className='login-form-button'>
                    登录
                  </Button>
                </Form.Item>
                <Col offset={2} span={20}>
                  <div className='options'>
                    <p onClick={() => this.jump('forget')}>忘记密码</p>
                    <p onClick={() => this.jump('regist')}>注册</p>
                  </div>
                </Col>
              </div>)
            :
            (<div className='qr-box'>
              <QRCode value={qr} size={300} ecLevel={'Q'}
                      logoWidth={50}
                      logoHeight={50}
                      logoOpacity={1}
                      quietZone={20}
                      logoImage={QRLOGO}/>

              <p className='txt'>请使用手机上宝宝树孕育app 扫一扫进行登录</p>
            </div>)
          }
        </div>
      </Form>
    )
  }

  doVisibilityChange = () => {
    let eventKey = 'visibilitychange'
    // 各个浏览器属性兼容
    if('hidden' in document) {
      eventKey = 'visibilitychange'
    } else if('mozHidden' in document) {
      eventKey = 'mozvisibilitychange'
    } else if('webkitHidden' in document) {
      eventKey = 'webkitvisibilitychange'
    } else if('msHidden' in document) {
      eventKey = 'msvisibilitychange'
    }
    document.addEventListener(eventKey, () => {
      // if (document.hidden) {
      //   if (this.qrCodeInterval) {
      //     clearInterval(this.qrCodeInterval)
      //   }
      // } else {
      //   this.checkQRCodeFuc()
      // }
    })
  }

}

const BrandLoginForm = Form.create({ name: 'normal_login' })(withRouter(LoginForm))

export default BrandLoginForm
