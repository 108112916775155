import React, { Component } from 'react'
import { Menu, Icon, Dropdown } from 'antd'
import PropTypes from 'prop-types'
import { loginOut } from '@/service/login'
import { basename } from '@/config/index'
import { get } from '@/service/user'
import { withRouter } from 'react-router'
import './index.less'

class UserComponent extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    userInfo: {}
  }

  componentDidMount () {
    this.getUserInfo()
  }

  // 获取登录信息
  getUserInfo = () => {
    get().then((userInfo) => {
        if (userInfo && userInfo.entryStatus == 0) {
           location.href = `${basename}/supplierEnter/enter`
        }
        this.setState({ userInfo })
    })
  }

  // 推出登录
  loginOutOpt = () => {
    loginOut().then(() => {
      this.props.history.push('/login')
    })
  }

  render () {
    const { userInfo } = this.state

    const menu = (<Menu className='pull-right'>
      <Menu.Item key='1' className='p-l-30 p-r-30'>
        <a onClick={this.loginOutOpt}>退出</a>
      </Menu.Item>
    </Menu>)

    const mobile = userInfo.mobile ? `当前登录账号:${userInfo.mobile}` : ''

    return (
      <div className='module-user h-full flex-center'>
        <Dropdown overlay={menu} trigger={['click']} className='c-po' placement='bottomLeft'>
          <div className='user-name flex-center'>
    <span className='m-l-20'>{userInfo.supplierName}<br/>{mobile}</span>
            <Icon type='down' className='icon-down m-l-10' />
          </div>
        </Dropdown>
      </div>
    )
  }
}

export default withRouter(UserComponent)
